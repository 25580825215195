<template>
  <!--begin::Stats Widget 10-->
  <div>
    <!--begin::Body-->
    <!-- <div class="card-body p-0"> -->
      <b-tabs
          active-nav-item-class="bg-success text-white"
         
        >
          <!-- <b-tab title="7 Hari Terakhir" active></b-tab> -->
          <b-tab title="Bulan Ini" active @click="Filter('thisMonth')"></b-tab>
          <b-tab title="Bulan Kemarin" @click="Filter('lastMonth')"></b-tab>
          <b-tab title="Tahun Ini" @click="Filter('thisYear')"></b-tab>
          <b-tab title="Periode" @click="periodFilter()"></b-tab>

        </b-tabs>

      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >

        <span class="symbol symbol-50 symbol-light-primary mr-2" v-if="tooltipPeriod != 'custom'">
          <button class="btn btn-success mr-1 d-inline mr-2" v-b-tooltip.hover title="Table" @click="$router.push('/report/pharmacy-report')"><i class="fas fa-table px-0"></i></button>
        </span>


        <div class="col-12 col-md-9" v-if="tooltipPeriod == 'custom'">
          <!-- Filter -->
            <div class="row align-items-center justify-content-start mt-n3">
              <div class="col-md-12">
                <div class="row">
                <span class="d-none d-sm-block">
                  <div class="row">
                    <div class="col">

                      <b-input-group>
                        <template #prepend>
                          <button class="btn btn-success mr-1 d-inline mr-2" v-b-tooltip.hover title="Table" @click="$router.push('/report/pharmacy-report')"><i class="fas fa-table px-0"></i></button>
                          <b-button squared @click="resetFilter" variant="danger"
                            >Reset</b-button
                          >
                          <b-button squared @click="generalFilter" variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
                
                </div>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <button class="btn btn-success mr-1 d-inline mr-2" v-b-tooltip.hover title="Table" @click="$router.push('/report/pharmacy-report')"><i class="fas fa-table px-0"></i></button>
                          <b-button squared @click="resetFilter" variant="danger"
                            >Reset</b-button
                          >
                          <b-button squared @click="generalFilter" variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
        </div>
        
        <div class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h3" v-if="sumLoad == false">Rp 0</span>
          <span class="text-dark-75 font-weight-bolder font-size-h3" v-else-if="sumLoad == true">Rp {{parseInt(sum[0].final_amount).toLocaleString('id-ID')}}</span>
          <span class="text-muted font-weight-bold mt-2">Total Pendapatan Apotik</span>
        </div>
      </div>

      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="bar"
        width="100%"
        style="height: auto;"
        v-if="chartLoad == true"
      ></apexchart>
      <!--end::Chart-->
    <!-- </div> -->
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 10-->
</template>

<script>
import { mapGetters } from "vuex";
import module from '@/core/modules/CrudModule.js'
import moment from 'moment'
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah :",
          data: []
        }
      ],
      sum:"",
      arr:[],
      chartLoad:false,
      sumLoad: false,
      // filter
      filter:{
        start_date:"",
        end_date:"",
      },
      tooltipPeriod:"thisMonth"
    };
  },
  methods:{
    Filter(tool){
       this.tooltipPeriod = tool
      this.chartLoad = false
      this.getData()
      mapGetters(["layoutConfig"])
    },
     generalFilter(){
      this.tooltipPeriod = "custom"
      this.chartLoad = false
      this.getData()
      mapGetters(["layoutConfig"])
    },
    periodFilter(){
      this.tooltipPeriod = "custom"
    },
    resetFilter(){
      this.chartLoad = false
      this.filter.start_date = ""
      this.filter.end_date = ""
      this.getData()
      mapGetters(["layoutConfig"])
    },
    async getSummary(){
       let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`
      this.sum = await module.get('pharmacy-reports-summary-amount-chart',`${filterParams}`)
      if(this.sum.length == 0){
        this.sumLoad = false
      }else{
        this.sumLoad = true
      }
    },

    async getData(){
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`
      let response = await module.get('pharmacy-chart',`${filterParams}`)
      console.log(response);
      console.log(response.data);
      this.getSummary()
      let colorData = ["#b90d49", "#f29724", "#2a6a6c", "#302a6c", "#404040"]
    
     
        this.series[0].data = response.map(response => parseFloat(response.amount))
        window.arrData = response.map(response => String(response.medicine_name))
        this.chartLoad = true
        console.log(this.series[0].data);

       this.chartOptions = {
      chart: {
        type: "bar",
        height: 150,
        toolbar: {
          show: false
        },
        // zoom: {
        //   enabled: false
        // },
        // sparkline: {
        //   enabled: true
        // }
      },
      // plotOptions: {},
      plotOptions: {
          bar: {
              horizontal: false,
              borderRadius: 0,
              columnWidth: '40%',
              
              // dataLabels: {
              //     position: 'top',
              //     maxItems: 100,
              //     hideOverflowingLabels: true,
              //     orientation: horizontal
              // }
          }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
      },
      xaxis: {
        categories: window.arrData,
        axisBorder: {
          show: true
        },
        crosshairs: {
          show: true,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: true,
          style: {
              colors: [],
              fontSize: '15px',
              // fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
        }
      },
      yaxis: {
        axisBorder: {
          show: true
        },
        show: true,
        min: 0,
         labels: {
          show: true,
          style: {
              colors: [],
              fontSize: '13px',
              // fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
          },
        }
        
      },
      scales: {
        xAxes: [{
            barPercentage: 0.4
        }]
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
         y: {
          formatter: function(val) {
            return "Rp" + parseInt(val).toLocaleString('id-ID') ;
          }
        }
      },
      // colors: '#a72e68',
      colors: '#a72e68',
      markers: {
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
    }

  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getSummary()
    this.getData()
    if(this.chartLoad == true){
      console.log("arr data",this.arr)
    }
    // reference; kt_stats_widget_12_chart
    
  }
};
</script>
